import { useTheme } from "@mui/material";
import Box from "../common/Box";
import Typography from "../common/Typography";
import Bar from "../common/Bar";
import Tooltip from "../common/Tooltip";
import CardWithAction from "./CardWithAction";
import {
  convertYYYYMMDD,
  formatBytes,
  getFirstNValue,
  getLoaderIcon,
} from "../../utils/commonUtils";
import { useEffect, useMemo, useState } from "react";
import { getCustomerId } from "../../utils/SessionHelper";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import { fetchLoaderListSlice } from "../../redux/slices/dataLoaderSlice";
import { useHistory } from "react-router-dom";
import NoData from "../common/NoData";
import Divider from "../common/Divider";

const SHOW_LOADER_COUNT = 3;

const DataLoaderWidget = () => {
  const [dataLoaders, setDataLoaders] = useState<Array<{
    DataLoaderListingResponse;
  }> | null>(null);
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const styles = {
    card: (loaderCount) => ({
      minWidth: "100%",
      overflowX: "auto",
      paddingX: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      display: "grid",
      gridTemplateColumns: `repeat(${loaderCount}, minmax(300px, min-content))`,
      columnGap: theme.spacing(3),
    }),
    docName: {
      width: "100%",
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    imgBg: {
      display: "flex",
      width: "30px",
      height: "30px",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.surfaceImgBg.main,
      borderRadius: "50%",
      flexShrink: 0,
    },
    name: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
      width: "100%",
    },
    lastRun: {
      display: "flex",
      gap: theme.spacing(0.5),
    },
    keyValue: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "baseline",
    },
    font20: {
      fontSize: "20px",
    },
    flexCol: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      width: "100%",
    },
    pointer: {
      cursor: "pointer",
    },
    truncateText: {
      //minWidth: "290px",
      width: "min-content",
      maxWidth: "calc(100% - 50px)",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    emptyState: {
      paddingBottom: theme.spacing(2),
    },
    docSize: {
      display: "flex",
      gap: theme.spacing(2),
      alignItems: "baseline",
    },
    divider: {
      height: "18px",
    },
  };

  const gotoDataLoaderDetailsPage = (dataLoader) => {
    history.push(`/data-loader/${dataLoader?.id}`);
  };

  const cardData = useMemo(
    () => ({
      dataLoaders: {
        title: "Data Loader Activity",
        children: dataLoaders?.length ? (
          <Box
            sx={styles.card(
              dataLoaders?.length > 3 ? SHOW_LOADER_COUNT : dataLoaders?.length
            )}
          >
            {dataLoaders?.map((loader: any) => (
              <Box
                sx={{ ...styles.flexCol, ...styles.pointer }}
                onClick={() => gotoDataLoaderDetailsPage(loader)}
              >
                <Box sx={styles.docName}>
                  <Box sx={styles.imgBg}>
                    <img
                      src={getLoaderIcon(loader?.type, true)}
                      alt="source"
                      height={"18px"}
                    />
                  </Box>
                  <Box sx={styles.name}>
                    <Tooltip title={loader?.name}>
                      <div>
                        <Typography
                          variant="body2"
                          color={theme.palette.surface60.main}
                          sx={styles.truncateText}
                        >
                          {loader?.name}
                        </Typography>
                      </div>
                    </Tooltip>
                  </Box>
                </Box>
                <Box sx={styles.lastRun}>
                  <Typography
                    variant="caption"
                    color={theme.palette.surface50.main}
                  >
                    Last run:
                  </Typography>
                  <Typography
                    variant="caption"
                    color={theme.palette.surface70.main}
                  >
                    {convertYYYYMMDD(loader.last_updated)}
                  </Typography>
                </Box>
                <Box sx={{ ...styles.flexCol, gap: theme.spacing(1.25) }}>
                  <Box sx={styles.docSize}>
                    <Box sx={styles.keyValue}>
                      <Typography
                        sx={styles.font20}
                        color={theme.palette.surface90.main}
                      >
                        {loader?.document_count}
                      </Typography>
                      <Typography
                        variant="caption"
                        color={theme.palette.surface50.main}
                      >
                        Documents
                      </Typography>
                    </Box>
                    <Divider orientation="vertical" sx={styles.divider} />
                    <Box sx={styles.keyValue}>
                      {/*<Typography
                        sx={styles.font20}
                        color={theme.palette.surface90.main}
                      >
                        {loader?.doc_size || 0} 
                      </Typography>*/}
                      <Typography
                        variant="caption"
                        color={theme.palette.surface50.main}
                      >
                        {formatBytes(loader?.documents_size) || 0}
                      </Typography>
                    </Box>
                  </Box>
                  {/*<Tooltip
                  title={
                    <LoaderTooltip
                      docs={loader?.docs}
                      labels={loader?.barDetails}
                    />
                  }
                >
                  <div>
                    <SpectrumGraph
                      width="200"
                      height="8"
                      data={loader?.barDetails}
                    />
                  </div>
                </Tooltip>*/}
                </Box>
                <Typography
                  variant="caption"
                  color={theme.palette.surface50.main}
                >
                  <Tooltip title={loader?.labels?.join(", ")}>
                    <span style={styles.pointer}>
                      {getFirstNValue(loader?.labels, 2)}
                    </span>
                  </Tooltip>
                </Typography>
              </Box>
            ))}
          </Box>
        ) : (
          <Box sx={styles.emptyState}>
            <NoData customText="No Data Loaders" />
          </Box>
        ),
      },
    }),
    [dataLoaders]
  );

  const getLoadersList = async () => {
    const payload = {
      customerId: getCustomerId(),
      count: SHOW_LOADER_COUNT,
    };
    const response = await dispatch(fetchLoaderListSlice(payload));
    if (response?.payload) {
      setDataLoaders(response.payload);
    }
  };

  const gotoDataLoaderListingPage = () => {
    history.push("/data-loaders");
  };

  useEffect(() => {
    getLoadersList();
  }, []);

  return (
    <CardWithAction
      cardData={cardData}
      buttonTitle="View All"
      onClick={gotoDataLoaderListingPage}
    />
  );
};

export default DataLoaderWidget;
