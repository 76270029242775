export const dataStoreTypes = [
  {
    label: "All",
    filter: "",
    value: "all",
  },
  {
    label: "Managed",
    filter: "managed",
    value: "managed",
  },
  {
    label: "AI",
    filter: "shadow",
    value: "ai",
  },
  {
    label: "Shadow",
    filter: "shadow",
    value: "shadow",
  },
];

export const aiDataStoreTabs = [
  {
    label: "All LLMs",
    filter: "",
    value: "all",
  },
  {
    label: "Deployed",
    filter: "llm-deployed",
    value: "deployed",
  },
  {
    label: "Under Finetuning",
    filter: "llm-under-fine-tuning",
    value: "under_fine_tuning",
  },
];

export const datastoreFilter = [
  {
    filter: "",
    value: "all",
  },
  {
    filter: "MANAGED",
    value: "managed",
  },
  {
    filter: "SHADOW_AI",
    value: "ai",
  },
  {
    filter: "SHADOW",
    value: "shadow",
  },
];

export const non_SOC = [
  "data-residency",
  "encryption",
  "logging",
  "public-access",
];

export const SOCStatusFilters = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Notified",
    value: "notified",
  },
  {
    label: "Archived",
    value: "archived",
  },
];

export const NonSOCStatusFilters = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Ticketed",
    value: "ticketed",
  },
  {
    label: "Resolved",
    value: "resolved",
  },
];

export const socActionType = "notify/archive";
export const nonSocActionType = "ticket/accept";

export const langchainData = [
  {
    name: "Langchain 1",
    version: "V0.0284",
  },
];

export const accountType = {
  AI: "Gen-AI Security",
  Data: "Data Security",
};

export const scanTypeAI = "ai-scan";
export const aiTypeGen = "Gen-AI";
export const aiTypeLangchain = "Langchain-AI";

export const promptTimestampFilter = [
  { value: "week", label: "Last 7 Days" },
  { value: "day", label: "Last 1 day" },
  { value: "hour", label: "Last 1 hour" },
];

export const promptSession = {
  context: "sensitiveContextCount",
  response: "sensitiveResponseCount",
  prompt: "sensitivePromptCount",
};

export const entityPolicy = ["apSensitiveIdentifier", "apRestrictedEntities"];
export const topicPolicy = ["apRestrictedTopic"];

export const sensitiveInfoTags = {
  social_security_numbers: "SSN",
  credit_cards: "CC",
  drivers_licenses: "DL",
  mobile_numbers: "MN",
};

export const SNIPPET_COUNT_KEY = "snippet_count";

export const APPLICATION_STR = "application";
export const DATA_LOADER_STR = "data-loader";

export const TagColorMapping = {
  harmful: "#FF655B",
  valuable: "#BF87D6",
  sensitive: "#3F466D",
};

export const LabelColorMapping = {
  Harmful: "#BF87D6",
  "US Drivers License": "#F2B485",
  "us-drivers-license": "#F2B485",
  "PII - SSN": "#85BDED",
  "us-ssn": "#85BDED",
  Financial: "#A76171",
  "PII - CC": "#ACCA9F",
};

export const LabelColorsList = [
  "#BF87D6",
  "#F2B485",
  "#85BDED",
  "#A76171",
  "#ACCA9F",
  "#F4BEB4",
  "#96DFD8",
  "#816DFF",
  "#AC6AEE",
];

export const MessageTypeColorMapping = {
  response: "#F88D50",
  prompt: "#6750A3",
};

export const DefinedLoaders = [
  "DirectoryLoader",
  "FileLoader",
  "JSONLoader",
  "CSVLoader",
  "DataFrameLoader",
  "DatavoloLoader",
  "S3FileLoader",
  "S3DirLoader",
  "UnstructuredMarkdownLoader",
  "UnstructuredPDFLoader",
  "UnstructuredFileLoader",
  "UnstructuredAPIFileLoader",
  "UnstructuredExcelLoader",
  "AmazonTextractPDFLoader",
  "GCSFileLoader",
  "GoogleDriveLoader",
  "PyPDFDirectoryLoader",
  "PyPDFLoader",
  "SharePointLoader",
  "SnowflakeLoader",
  "PebbloTextLoader",
]
