import { IconButton, useTheme } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import SlackIcon from "../../../assets/img/slack.svg";
import MoreVert from "@mui/icons-material/MoreVert";
import TextButton from "../../common/TextButton";
import Box from "../../common/Box";
import Typography from "../../common/Typography";
import Divider from "../../common/Divider";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../../common/Table";
import Checkbox from "../../common/Checkbox";
import Chip from "../../common/Chip";
import DatastoreTicketModal from "../../modal/datastore-ticket-modal/DatastoreTicketModal";
import { TextField } from "../../common/TextField";
import ReactDOMServer from "react-dom/server";
import NoData from "../../common/NoData";
import { useDispatch, useSelector } from "react-redux";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import FlagIcon from "@mui/icons-material/Flag";
import Loader from "../../common/Loader";
import Tooltip from "../../common/Tooltip";
import SuggestLabelDialog from "../SuggestLabelDialog";
import { getCustomerId, getID } from "../../../utils/SessionHelper";
import { suggestClassificationLabelForDocSlice } from "../../../redux/slices/frameworkPageSlice";
import { Toaster, TOASTER_TYPES } from "../../../utils/toaster";
import DiscardLabelDialog from "../DiscardLabelDialog";
import ViewMoreBlock from "../../common/ViewMoreBlock";
import JiraIcon from "../../icons/JiraIcon";

const NUM_OF_DEFAULT_CHARACTERS = 250;

const MessageDetailBlock = ({ data, index }) => {
  const theme = useTheme();
  const styles = {
    messageContainer: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.25),
    },
    messageCard: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    messageFontSize: {
      fontSize: "13px",
    },
    dateText: {
      display: "flex",
      gap: theme.spacing(0.5),
    },
    messageData: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    keyValue: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.75),
    },
    tableCellAction: {
      whiteSpace: "nowrap",
      display: "flex",
      alignItems: "center",
      fontSize: "13px",
      color: theme.palette.primaryBlue.main,
      cursor: "pointer",
      "&.Mui-disabled": {
        color: theme.palette.surface20.main,
        pointerEvents: "unset",
        cursor: "not-allowed",
      },
    },
    originChain: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2.25),
      alignItems: "start",
    },
    policyChip: {
      display: "flex",
      width: "min-content",
      //backgroundColor: "rgba(255, 101, 91, 0.20)",
      backgroundColor: theme.palette.surface10.main,
      border: `1px solid ${theme.palette.warning.main}`,
      borderRadius: theme.spacing(0.5),
    },
  };

  return (
    <Box sx={styles.messageContainer}>
      <Box sx={styles.messageCard}>
        <Box sx={styles.messageData}>
          <Box sx={styles.keyValue}>
            <Typography variant="caption" color={theme.palette.surface40.main}>
              Snippet
            </Typography>
            <ViewMoreBlock
              value={data.doc}
              length={NUM_OF_DEFAULT_CHARACTERS}
            />
          </Box>
          <Box sx={styles.keyValue}>
            <Typography variant="caption" color={theme.palette.surface40.main}>
              Retrieved From
            </Typography>
            <ViewMoreBlock
              value={data?.retrieved_from}
              length={NUM_OF_DEFAULT_CHARACTERS}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const SnippetDetails = ({
  isMessageLoading,
  messagesList = [],
  frameworkData = {},
  selectedSensitiveLabel = { label: "", type: "" },
  sensitiveData,
}: {
  isMessageLoading: boolean;
  frameworkData: any;
  messagesList: any;
  selectedSensitiveLabel: { label: string; type: string };
  sensitiveData: any;
}) => {
  const [globalTicketModal, setGlobalTicketModal] = useState(false);
  const [sensitiveLabelCount, setSensitiveLabelCount] = useState(0);

  const [labelList, setLabelList] = useState<Array<string>>([]);

  const theme = useTheme();
  const dispatch = useDispatch();
  const topics = useSelector((state: any) => state.policy.topics);
  const styles = {
    messagePanel: {
      borderRadius: theme.spacing(0.5),
      height: "400px",
      overflow: "hidden",
    },
    checkbox: {
      "& .MuiSvgIcon-root": { fontSize: "16px" },
    },
    tableHeader: {
      "& .MuiTableCell-head": {
        color: theme.palette.surface50.main,
        fontSize: "13px",
        lineHeight: "1.125rem",
        background: theme.palette.surface0.main,
        borderBottom: `1px solid ${theme.palette.surface20.main} !important`,
        paddingY: theme.spacing(0.75),
        zIndex: 10,
      },
    },
    checkboxCell: {
      verticalAlign: "top",
    },
    tableCellAction: {
      whiteSpace: "nowrap",
      display: "flex",
      alignItems: "center",
      fontSize: "13px",
      color: theme.palette.primaryBlue.main,
      cursor: "pointer",
      "&.Mui-disabled": {
        color: theme.palette.surface20.main,
        pointerEvents: "unset",
        cursor: "not-allowed",
      },
    },
    bulkActions: {
      display: "flex",
      gap: theme.spacing(2),
    },
    actionImg: {
      height: "16px",
    },
    actionsCell: {
      display: "flex",
      gap: theme.spacing(1),
      //height: "100%",
      // flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
    },
    tableHeight: {
      height: "100%",
    },
    modalBody: {
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    modalContent: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    tableContainer: {
      minHeight: "100%",
      height: "100%",
      maxHeight: "400px",
    },
    tableCell: {
      verticalAlign: "top",
    },
    iconButton: {
      padding: theme.spacing(0.5),
    },
    dFlex: {
      display: "flex",
    },
    dividerHeight: { height: "13px" },
    thumbsIcon: {
      color: theme.palette.surface30.main,
      height: "20px",
      width: "20px",
    },
    thumbsIconBox: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
    },
  };

  useEffect(() => {
    setLabelList([...topics.map((topic) => topic.name), "Other"]);
  }, [topics]);

  return (
    <Box sx={styles.messagePanel}>
      <TableContainer sx={styles.tableContainer}>
        <Table sx={styles.tableHeight}>
          <TableHead sx={styles.tableHeader}>
            <TableRow noHover={true}>
              {/*<TableCell>
                <Tooltip title="Not available">
                  <div>
                    <Checkbox disabled label="selectAll" sx={styles.checkbox} />
                  </div>
                </Tooltip>
              </TableCell>*/}
              <TableCell>
                <Box sx={styles.bulkActions}>
                  <Divider orientation="vertical" flexItem />
                  <Tooltip title="Not available">
                    <div style={styles.dFlex}>
                      <TextButton
                        disabled
                        startIcon={
                         <JiraIcon disabled={true} />
                        }
                        // onClick={(event) => {
                        //   event.stopPropagation();
                        //   setGlobalTicketModal(true);
                        //   setTicketTitle(selectedSensitiveLabel);
                        //   setTicketDescription({
                        //     ...frameworkData,
                        //     ...messageList[0],
                        //   });
                        //   setTicketModal(true);
                        // }}
                        sx={styles.tableCellAction}
                      >
                        Open Jira Ticket
                      </TextButton>
                    </div>
                  </Tooltip>
                  <Divider orientation="vertical" flexItem />
                  <Tooltip title="Not available">
                    <div style={styles.dFlex}>
                      <TextButton
                        disabled
                        startIcon={
                          <img
                            src={SlackIcon}
                            alt="slack"
                            style={styles.actionImg}
                          />
                        }
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                        sx={styles.tableCellAction}
                      >
                        Notify on Slack
                      </TextButton>
                    </div>
                  </Tooltip>
                  <Divider orientation="vertical" flexItem />
                  <Tooltip title="Not available">
                    <div>
                      <IconButton disabled sx={styles.iconButton}>
                        <TextButton disabled>
                          <MoreVert fontSize="small" />
                        </TextButton>
                      </IconButton>
                    </div>
                  </Tooltip>
                </Box>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isMessageLoading ? (
              <TableCell colspan="2" borderBottom={false}>
                <Loader />
              </TableCell>
            ) : messagesList?.length ? (
              messagesList?.map((message, index) => {
                const suggestedLabel = message?.label_feedback?.suggested_label;
                const otherLabel = message?.label_feedback?.otherLabel;
                return (
                  <TableRow noHover={true}>
                    <TableCell sx={styles.tableCell}>
                      <MessageDetailBlock data={message} index={index} />
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      <Box sx={styles.actionsCell}>
                        <TextButton
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                          disabled={true}
                          startIcon={<JiraIcon disabled={true} />}
                          sx={styles.tableCellAction}
                        >
                          Open Ticket
                        </TextButton>
                        <Divider
                          orientation="vertical"
                          sx={styles.dividerHeight}
                        />
                        <Box sx={styles.thumbsIconBox}>
                          <Tooltip
                            title={
                              suggestedLabel
                                ? `You Suggested new label 
                            as ‘${suggestedLabel}${
                                    otherLabel ? `-${otherLabel}` : ""
                                  }’.`
                                : "Report As Inaccurate Label"
                            }
                            placement="top"
                          >
                            <div>
                              <TextButton
                                onClick={() => {}}
                                disabled={true}
                                startIcon={
                                  suggestedLabel ? (
                                    <FlagIcon />
                                  ) : (
                                    <FlagOutlinedIcon />
                                  )
                                }
                              >
                                {suggestedLabel ? "Reported" : "Report"}
                              </TextButton>
                            </div>
                          </Tooltip>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableCell colspan="2" borderBottom={false}>
                <NoData customText="No data found." />
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SnippetDetails;
